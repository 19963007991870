.header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    align-self: center;
}

.header-container > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 2.9em;
    line-height: 57px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #000000;
    margin: 0;
}

.header-container > p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4em;
    line-height: 1.4em;
    text-align: center;
    margin: 10px 0;
}

/* For small width screens */
@media only screen and (max-width: 640px) {
    .header-container {
        width: 80%;
    }
    .header-container > p {
        font-size: 1.2em;
        line-height: 1.2em;
    }
}
