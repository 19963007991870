.modal-overlay {
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
    background-color: #fefefe;
    /* margin: 15vh auto;  */
    padding: 20px;
    border-radius: 2px;
    width: 80%; /* Could be more or less, depending on screen size */
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    font-family: Poppins;
    font-style: normal;
}

.upper-section {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    justify-content: space-between;
    margin: 10px 0;
}

.upper-section > h1 {
    margin: 0;
}

.close {
    margin: 0;
    font-size: 1.4em;
}

.close:hover {
    cursor: pointer;
}

.middle-section-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.middle-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 50%;
}

.middle-section > * {
    margin: 5px 0;
}

.middle-section-image {
    max-width: 40%;
    display: flex;
    align-self: center;
}

.aam-cover {
    width: 100%;
}

@media only screen and (max-width: 640px) {
    .middle-section {
        max-width: 100%;
    }

    .middle-section-image {
        max-width: 100%;
    }
}

.lower-section {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 0;
}

.modal-btn {
    border-width: 0;
    outline: none;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    margin-left: 15px;
    padding: 12px 24px;
    transition: background-color 0.3s;
    color: #ecf0f1;
    text-decoration: none;
}

.modal-btn:hover {
    cursor: pointer;
}

.btn-green {
    background-color: #2ecc71;
}

.btn-green:hover,
.btn-green:focus {
    background-color: #27ae60;
}

.btn-red {
    background-color: #e74c3c;
}

.btn-red:hover,
.btn-red:focus {
    background-color: #c0392b;
}
