body {
    min-height: 100vh;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    font-family: Poppins;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.section-container {
    box-sizing: border-box;
    min-height: 100vh;
    position: relative;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

/* For small width screens */
@media only screen and (max-width: 640px) {
    .section-container {
        padding: 100px 0;
    }
}
