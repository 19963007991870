.about-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 60px 80px 80px 80px;
}

.about-main-left,
.about-main-right {
    width: 50%;
}

.about-main-right {
    display: flex;
    justify-content: flex-end;
}

.about-main-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-link-element {
    color: black;
}

.about-sub-head {
    margin: 0;
}

.about-details {
    margin: 10px 0 32px 0;
}

.about-anime {
    width: 520px;
    max-height: 100%;
    max-width: 100%;
}

.vector-frame {
    position: absolute;
    z-index: -10;
    left: 0;
    bottom: 0;
    width: 55%;
}

.about-vector {
    display: block;
    max-height: 100%;
    max-width: 100%;
}

/* For small width screens */
@media only screen and (max-width: 640px) {
    .vector-frame {
        width: 90%;
    }
    .about-head {
        width: 80%;
    }
    .about-head > p {
        font-size: 1.2em;
        line-height: 1.2em;
    }
    .about-main {
        padding: 50px 32px;
    }
    .about-main-left,
    .about-main-right {
        width: 100%;
    }
}
