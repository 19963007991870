.skill-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 80px 20%;
}

.skill {
    display: flex;
    flex-direction: column;
    width: 150px;
    align-items: center;
    margin-bottom: 20px;
}

.skill > img {
    width: 108px;
    margin-bottom: 15px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.skill > p {
    margin: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
}

.skills-vector-frame {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    z-index: -10;
    right: 0;
    bottom: 0;
    width: 80%;
}

.skills-vector {
    max-height: 100%;
    width: 100%;
}

/* For small width screens */
@media only screen and (max-width: 640px) {
    .skills-vector-frame {
        width: 90%;
    }
    .skill-card-container {
        margin: 80px 10%;
    }
}
